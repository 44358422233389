import { dew as _XMLDOMErrorHandlerDew } from "./XMLDOMErrorHandler";
import { dew as _XMLDOMStringListDew } from "./XMLDOMStringList";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDOMConfiguration, XMLDOMErrorHandler, XMLDOMStringList;
    XMLDOMErrorHandler = _XMLDOMErrorHandlerDew();
    XMLDOMStringList = _XMLDOMStringListDew();

    exports = XMLDOMConfiguration = function () {
      function XMLDOMConfiguration() {
        var clonedSelf;
        (this || _global).defaultParams = {
          "canonical-form": false,
          "cdata-sections": false,
          "comments": false,
          "datatype-normalization": false,
          "element-content-whitespace": true,
          "entities": true,
          "error-handler": new XMLDOMErrorHandler(),
          "infoset": true,
          "validate-if-schema": false,
          "namespaces": true,
          "namespace-declarations": true,
          "normalize-characters": false,
          "schema-location": "",
          "schema-type": "",
          "split-cdata-sections": true,
          "validate": false,
          "well-formed": true
        };
        (this || _global).params = clonedSelf = Object.create((this || _global).defaultParams);
      }

      Object.defineProperty(XMLDOMConfiguration.prototype, "parameterNames", {
        get: function () {
          return new XMLDOMStringList(Object.keys((this || _global).defaultParams));
        }
      });

      XMLDOMConfiguration.prototype.getParameter = function (name) {
        if ((this || _global).params.hasOwnProperty(name)) {
          return (this || _global).params[name];
        } else {
          return null;
        }
      };

      XMLDOMConfiguration.prototype.canSetParameter = function (name, value) {
        return true;
      };

      XMLDOMConfiguration.prototype.setParameter = function (name, value) {
        if (value != null) {
          return (this || _global).params[name] = value;
        } else {
          return delete (this || _global).params[name];
        }
      };

      return XMLDOMConfiguration;
    }();
  }).call(exports);
  return exports;
}