var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLNodeList;

    exports = XMLNodeList = function () {
      function XMLNodeList(nodes) {
        (this || _global).nodes = nodes;
      }

      Object.defineProperty(XMLNodeList.prototype, "length", {
        get: function () {
          return (this || _global).nodes.length || 0;
        }
      });

      XMLNodeList.prototype.clone = function () {
        return (this || _global).nodes = null;
      };

      XMLNodeList.prototype.item = function (index) {
        return (this || _global).nodes[index] || null;
      };

      return XMLNodeList;
    }();
  }).call(exports);
  return exports;
}