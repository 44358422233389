import { dew as _XMLWriterBaseDew } from "./XMLWriterBase";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLStringWriter,
        XMLWriterBase,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLWriterBase = _XMLWriterBaseDew();

    exports = XMLStringWriter = function (superClass) {
      extend(XMLStringWriter, superClass);

      function XMLStringWriter(options) {
        XMLStringWriter.__super__.constructor.call(this || _global, options);
      }

      XMLStringWriter.prototype.document = function (doc, options) {
        var child, i, len, r, ref;
        options = this.filterOptions(options);
        r = "";
        ref = doc.children;

        for (i = 0, len = ref.length; i < len; i++) {
          child = ref[i];
          r += this.writeChildNode(child, options, 0);
        }

        if (options.pretty && r.slice(-options.newline.length) === options.newline) {
          r = r.slice(0, -options.newline.length);
        }

        return r;
      };

      return XMLStringWriter;
    }(XMLWriterBase);
  }).call(exports);
  return exports;
}