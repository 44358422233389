import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLDOMImplementationDew } from "./XMLDOMImplementation";
import { dew as _XMLDOMConfigurationDew } from "./XMLDOMConfiguration";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLStringifierDew } from "./XMLStringifier";
import { dew as _XMLStringWriterDew } from "./XMLStringWriter";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLDOMConfiguration,
        XMLDOMImplementation,
        XMLDocument,
        XMLNode,
        XMLStringWriter,
        XMLStringifier,
        isPlainObject,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    isPlainObject = _UtilityDew().isPlainObject;
    XMLDOMImplementation = _XMLDOMImplementationDew();
    XMLDOMConfiguration = _XMLDOMConfigurationDew();
    XMLNode = _XMLNodeDew();
    NodeType = _NodeTypeDew();
    XMLStringifier = _XMLStringifierDew();
    XMLStringWriter = _XMLStringWriterDew();

    exports = XMLDocument = function (superClass) {
      extend(XMLDocument, superClass);

      function XMLDocument(options) {
        XMLDocument.__super__.constructor.call(this || _global, null);

        (this || _global).name = "#document";
        (this || _global).type = NodeType.Document;
        (this || _global).documentURI = null;
        (this || _global).domConfig = new XMLDOMConfiguration();
        options || (options = {});

        if (!options.writer) {
          options.writer = new XMLStringWriter();
        }

        (this || _global).options = options;
        (this || _global).stringify = new XMLStringifier(options);
      }

      Object.defineProperty(XMLDocument.prototype, "implementation", {
        value: new XMLDOMImplementation()
      });
      Object.defineProperty(XMLDocument.prototype, "doctype", {
        get: function () {
          var child, i, len, ref;
          ref = (this || _global).children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            if (child.type === NodeType.DocType) {
              return child;
            }
          }

          return null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "documentElement", {
        get: function () {
          return (this || _global).rootObject || null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "inputEncoding", {
        get: function () {
          return null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "strictErrorChecking", {
        get: function () {
          return false;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "xmlEncoding", {
        get: function () {
          if ((this || _global).children.length !== 0 && (this || _global).children[0].type === NodeType.Declaration) {
            return (this || _global).children[0].encoding;
          } else {
            return null;
          }
        }
      });
      Object.defineProperty(XMLDocument.prototype, "xmlStandalone", {
        get: function () {
          if ((this || _global).children.length !== 0 && (this || _global).children[0].type === NodeType.Declaration) {
            return (this || _global).children[0].standalone === "yes";
          } else {
            return false;
          }
        }
      });
      Object.defineProperty(XMLDocument.prototype, "xmlVersion", {
        get: function () {
          if ((this || _global).children.length !== 0 && (this || _global).children[0].type === NodeType.Declaration) {
            return (this || _global).children[0].version;
          } else {
            return "1.0";
          }
        }
      });
      Object.defineProperty(XMLDocument.prototype, "URL", {
        get: function () {
          return (this || _global).documentURI;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "origin", {
        get: function () {
          return null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "compatMode", {
        get: function () {
          return null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "characterSet", {
        get: function () {
          return null;
        }
      });
      Object.defineProperty(XMLDocument.prototype, "contentType", {
        get: function () {
          return null;
        }
      });

      XMLDocument.prototype.end = function (writer) {
        var writerOptions;
        writerOptions = {};

        if (!writer) {
          writer = (this || _global).options.writer;
        } else if (isPlainObject(writer)) {
          writerOptions = writer;
          writer = (this || _global).options.writer;
        }

        return writer.document(this || _global, writer.filterOptions(writerOptions));
      };

      XMLDocument.prototype.toString = function (options) {
        return (this || _global).options.writer.document(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLDocument.prototype.createElement = function (tagName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createDocumentFragment = function () {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createTextNode = function (data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createComment = function (data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createCDATASection = function (data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createProcessingInstruction = function (target, data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createAttribute = function (name) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createEntityReference = function (name) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.getElementsByTagName = function (tagname) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.importNode = function (importedNode, deep) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createElementNS = function (namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createAttributeNS = function (namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.getElementsByTagNameNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.getElementById = function (elementId) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.adoptNode = function (source) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.normalizeDocument = function () {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.renameNode = function (node, namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.getElementsByClassName = function (classNames) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createEvent = function (eventInterface) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createRange = function () {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createNodeIterator = function (root, whatToShow, filter) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLDocument.prototype.createTreeWalker = function (root, whatToShow, filter) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      return XMLDocument;
    }(XMLNode);
  }).call(exports);
  return exports;
}