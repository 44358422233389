import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLAttributeDew } from "./XMLAttribute";
import { dew as _XMLNamedNodeMapDew } from "./XMLNamedNodeMap";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLAttribute,
        XMLElement,
        XMLNamedNodeMap,
        XMLNode,
        getValue,
        isFunction,
        isObject,
        ref,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    ref = _UtilityDew(), isObject = ref.isObject, isFunction = ref.isFunction, getValue = ref.getValue;
    XMLNode = _XMLNodeDew();
    NodeType = _NodeTypeDew();
    XMLAttribute = _XMLAttributeDew();
    XMLNamedNodeMap = _XMLNamedNodeMapDew();

    exports = XMLElement = function (superClass) {
      extend(XMLElement, superClass);

      function XMLElement(parent, name, attributes) {
        var child, j, len, ref1;

        XMLElement.__super__.constructor.call(this || _global, parent);

        if (name == null) {
          throw new Error("Missing element name. " + this.debugInfo());
        }

        (this || _global).name = (this || _global).stringify.name(name);
        (this || _global).type = NodeType.Element;
        (this || _global).attribs = {};
        (this || _global).schemaTypeInfo = null;

        if (attributes != null) {
          this.attribute(attributes);
        }

        if (parent.type === NodeType.Document) {
          (this || _global).isRoot = true;
          (this || _global).documentObject = parent;
          parent.rootObject = this || _global;

          if (parent.children) {
            ref1 = parent.children;

            for (j = 0, len = ref1.length; j < len; j++) {
              child = ref1[j];

              if (child.type === NodeType.DocType) {
                child.name = (this || _global).name;
                break;
              }
            }
          }
        }
      }

      Object.defineProperty(XMLElement.prototype, "tagName", {
        get: function () {
          return (this || _global).name;
        }
      });
      Object.defineProperty(XMLElement.prototype, "namespaceURI", {
        get: function () {
          return "";
        }
      });
      Object.defineProperty(XMLElement.prototype, "prefix", {
        get: function () {
          return "";
        }
      });
      Object.defineProperty(XMLElement.prototype, "localName", {
        get: function () {
          return (this || _global).name;
        }
      });
      Object.defineProperty(XMLElement.prototype, "id", {
        get: function () {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });
      Object.defineProperty(XMLElement.prototype, "className", {
        get: function () {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });
      Object.defineProperty(XMLElement.prototype, "classList", {
        get: function () {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });
      Object.defineProperty(XMLElement.prototype, "attributes", {
        get: function () {
          if (!(this || _global).attributeMap || !(this || _global).attributeMap.nodes) {
            (this || _global).attributeMap = new XMLNamedNodeMap((this || _global).attribs);
          }

          return (this || _global).attributeMap;
        }
      });

      XMLElement.prototype.clone = function () {
        var att, attName, clonedSelf, ref1;
        clonedSelf = Object.create(this || _global);

        if (clonedSelf.isRoot) {
          clonedSelf.documentObject = null;
        }

        clonedSelf.attribs = {};
        ref1 = (this || _global).attribs;

        for (attName in ref1) {
          if (!hasProp.call(ref1, attName)) continue;
          att = ref1[attName];
          clonedSelf.attribs[attName] = att.clone();
        }

        clonedSelf.children = [];

        (this || _global).children.forEach(function (child) {
          var clonedChild;
          clonedChild = child.clone();
          clonedChild.parent = clonedSelf;
          return clonedSelf.children.push(clonedChild);
        });

        return clonedSelf;
      };

      XMLElement.prototype.attribute = function (name, value) {
        var attName, attValue;

        if (name != null) {
          name = getValue(name);
        }

        if (isObject(name)) {
          for (attName in name) {
            if (!hasProp.call(name, attName)) continue;
            attValue = name[attName];
            this.attribute(attName, attValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          if ((this || _global).options.keepNullAttributes && value == null) {
            (this || _global).attribs[name] = new XMLAttribute(this || _global, name, "");
          } else if (value != null) {
            (this || _global).attribs[name] = new XMLAttribute(this || _global, name, value);
          }
        }

        return this || _global;
      };

      XMLElement.prototype.removeAttribute = function (name) {
        var attName, j, len;

        if (name == null) {
          throw new Error("Missing attribute name. " + this.debugInfo());
        }

        name = getValue(name);

        if (Array.isArray(name)) {
          for (j = 0, len = name.length; j < len; j++) {
            attName = name[j];
            delete (this || _global).attribs[attName];
          }
        } else {
          delete (this || _global).attribs[name];
        }

        return this || _global;
      };

      XMLElement.prototype.toString = function (options) {
        return (this || _global).options.writer.element(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLElement.prototype.att = function (name, value) {
        return this.attribute(name, value);
      };

      XMLElement.prototype.a = function (name, value) {
        return this.attribute(name, value);
      };

      XMLElement.prototype.getAttribute = function (name) {
        if ((this || _global).attribs.hasOwnProperty(name)) {
          return (this || _global).attribs[name].value;
        } else {
          return null;
        }
      };

      XMLElement.prototype.setAttribute = function (name, value) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getAttributeNode = function (name) {
        if ((this || _global).attribs.hasOwnProperty(name)) {
          return (this || _global).attribs[name];
        } else {
          return null;
        }
      };

      XMLElement.prototype.setAttributeNode = function (newAttr) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.removeAttributeNode = function (oldAttr) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getElementsByTagName = function (name) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getAttributeNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.setAttributeNS = function (namespaceURI, qualifiedName, value) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.removeAttributeNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getAttributeNodeNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.setAttributeNodeNS = function (newAttr) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getElementsByTagNameNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.hasAttribute = function (name) {
        return (this || _global).attribs.hasOwnProperty(name);
      };

      XMLElement.prototype.hasAttributeNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.setIdAttribute = function (name, isId) {
        if ((this || _global).attribs.hasOwnProperty(name)) {
          return (this || _global).attribs[name].isId;
        } else {
          return isId;
        }
      };

      XMLElement.prototype.setIdAttributeNS = function (namespaceURI, localName, isId) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.setIdAttributeNode = function (idAttr, isId) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getElementsByTagName = function (tagname) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getElementsByTagNameNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.getElementsByClassName = function (classNames) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLElement.prototype.isEqualNode = function (node) {
        var i, j, ref1;

        if (!XMLElement.__super__.isEqualNode.apply(this || _global, arguments).isEqualNode(node)) {
          return false;
        }

        if (node.namespaceURI !== (this || _global).namespaceURI) {
          return false;
        }

        if (node.prefix !== (this || _global).prefix) {
          return false;
        }

        if (node.localName !== (this || _global).localName) {
          return false;
        }

        if (node.attribs.length !== (this || _global).attribs.length) {
          return false;
        }

        for (i = j = 0, ref1 = (this || _global).attribs.length - 1; 0 <= ref1 ? j <= ref1 : j >= ref1; i = 0 <= ref1 ? ++j : --j) {
          if (!(this || _global).attribs[i].isEqualNode(node.attribs[i])) {
            return false;
          }
        }

        return true;
      };

      return XMLElement;
    }(XMLNode);
  }).call(exports);
  return exports;
}