import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLElementDew } from "./XMLElement";
import { dew as _XMLCDataDew } from "./XMLCData";
import { dew as _XMLCommentDew } from "./XMLComment";
import { dew as _XMLDeclarationDew } from "./XMLDeclaration";
import { dew as _XMLDocTypeDew } from "./XMLDocType";
import { dew as _XMLRawDew } from "./XMLRaw";
import { dew as _XMLTextDew } from "./XMLText";
import { dew as _XMLProcessingInstructionDew } from "./XMLProcessingInstruction";
import { dew as _XMLDummyDew } from "./XMLDummy";
import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLNodeListDew } from "./XMLNodeList";
import { dew as _XMLNamedNodeMapDew } from "./XMLNamedNodeMap";
import { dew as _DocumentPositionDew } from "./DocumentPosition";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var DocumentPosition,
        NodeType,
        XMLCData,
        XMLComment,
        XMLDeclaration,
        XMLDocType,
        XMLDummy,
        XMLElement,
        XMLNamedNodeMap,
        XMLNode,
        XMLNodeList,
        XMLProcessingInstruction,
        XMLRaw,
        XMLText,
        getValue,
        isEmpty,
        isFunction,
        isObject,
        ref1,
        hasProp = {}.hasOwnProperty;
    ref1 = _UtilityDew(), isObject = ref1.isObject, isFunction = ref1.isFunction, isEmpty = ref1.isEmpty, getValue = ref1.getValue;
    XMLElement = null;
    XMLCData = null;
    XMLComment = null;
    XMLDeclaration = null;
    XMLDocType = null;
    XMLRaw = null;
    XMLText = null;
    XMLProcessingInstruction = null;
    XMLDummy = null;
    NodeType = null;
    XMLNodeList = null;
    XMLNamedNodeMap = null;
    DocumentPosition = null;

    exports = XMLNode = function () {
      function XMLNode(parent1) {
        (this || _global).parent = parent1;

        if ((this || _global).parent) {
          (this || _global).options = (this || _global).parent.options;
          (this || _global).stringify = (this || _global).parent.stringify;
        }

        (this || _global).value = null;
        (this || _global).children = [];
        (this || _global).baseURI = null;

        if (!XMLElement) {
          XMLElement = _XMLElementDew();
          XMLCData = _XMLCDataDew();
          XMLComment = _XMLCommentDew();
          XMLDeclaration = _XMLDeclarationDew();
          XMLDocType = _XMLDocTypeDew();
          XMLRaw = _XMLRawDew();
          XMLText = _XMLTextDew();
          XMLProcessingInstruction = _XMLProcessingInstructionDew();
          XMLDummy = _XMLDummyDew();
          NodeType = _NodeTypeDew();
          XMLNodeList = _XMLNodeListDew();
          XMLNamedNodeMap = _XMLNamedNodeMapDew();
          DocumentPosition = _DocumentPositionDew();
        }
      }

      Object.defineProperty(XMLNode.prototype, "nodeName", {
        get: function () {
          return (this || _global).name;
        }
      });
      Object.defineProperty(XMLNode.prototype, "nodeType", {
        get: function () {
          return (this || _global).type;
        }
      });
      Object.defineProperty(XMLNode.prototype, "nodeValue", {
        get: function () {
          return (this || _global).value;
        }
      });
      Object.defineProperty(XMLNode.prototype, "parentNode", {
        get: function () {
          return (this || _global).parent;
        }
      });
      Object.defineProperty(XMLNode.prototype, "childNodes", {
        get: function () {
          if (!(this || _global).childNodeList || !(this || _global).childNodeList.nodes) {
            (this || _global).childNodeList = new XMLNodeList((this || _global).children);
          }

          return (this || _global).childNodeList;
        }
      });
      Object.defineProperty(XMLNode.prototype, "firstChild", {
        get: function () {
          return (this || _global).children[0] || null;
        }
      });
      Object.defineProperty(XMLNode.prototype, "lastChild", {
        get: function () {
          return (this || _global).children[(this || _global).children.length - 1] || null;
        }
      });
      Object.defineProperty(XMLNode.prototype, "previousSibling", {
        get: function () {
          var i;
          i = (this || _global).parent.children.indexOf(this || _global);
          return (this || _global).parent.children[i - 1] || null;
        }
      });
      Object.defineProperty(XMLNode.prototype, "nextSibling", {
        get: function () {
          var i;
          i = (this || _global).parent.children.indexOf(this || _global);
          return (this || _global).parent.children[i + 1] || null;
        }
      });
      Object.defineProperty(XMLNode.prototype, "ownerDocument", {
        get: function () {
          return this.document() || null;
        }
      });
      Object.defineProperty(XMLNode.prototype, "textContent", {
        get: function () {
          var child, j, len, ref2, str;

          if ((this || _global).nodeType === NodeType.Element || (this || _global).nodeType === NodeType.DocumentFragment) {
            str = "";
            ref2 = (this || _global).children;

            for (j = 0, len = ref2.length; j < len; j++) {
              child = ref2[j];

              if (child.textContent) {
                str += child.textContent;
              }
            }

            return str;
          } else {
            return null;
          }
        },
        set: function (value) {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });

      XMLNode.prototype.setParent = function (parent) {
        var child, j, len, ref2, results;
        (this || _global).parent = parent;

        if (parent) {
          (this || _global).options = parent.options;
          (this || _global).stringify = parent.stringify;
        }

        ref2 = (this || _global).children;
        results = [];

        for (j = 0, len = ref2.length; j < len; j++) {
          child = ref2[j];
          results.push(child.setParent(this || _global));
        }

        return results;
      };

      XMLNode.prototype.element = function (name, attributes, text) {
        var childNode, item, j, k, key, lastChild, len, len1, ref2, ref3, val;
        lastChild = null;

        if (attributes === null && text == null) {
          ref2 = [{}, null], attributes = ref2[0], text = ref2[1];
        }

        if (attributes == null) {
          attributes = {};
        }

        attributes = getValue(attributes);

        if (!isObject(attributes)) {
          ref3 = [attributes, text], text = ref3[0], attributes = ref3[1];
        }

        if (name != null) {
          name = getValue(name);
        }

        if (Array.isArray(name)) {
          for (j = 0, len = name.length; j < len; j++) {
            item = name[j];
            lastChild = this.element(item);
          }
        } else if (isFunction(name)) {
          lastChild = this.element(name.apply());
        } else if (isObject(name)) {
          for (key in name) {
            if (!hasProp.call(name, key)) continue;
            val = name[key];

            if (isFunction(val)) {
              val = val.apply();
            }

            if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertAttKey && key.indexOf((this || _global).stringify.convertAttKey) === 0) {
              lastChild = this.attribute(key.substr((this || _global).stringify.convertAttKey.length), val);
            } else if (!(this || _global).options.separateArrayItems && Array.isArray(val) && isEmpty(val)) {
              lastChild = this.dummy();
            } else if (isObject(val) && isEmpty(val)) {
              lastChild = this.element(key);
            } else if (!(this || _global).options.keepNullNodes && val == null) {
              lastChild = this.dummy();
            } else if (!(this || _global).options.separateArrayItems && Array.isArray(val)) {
              for (k = 0, len1 = val.length; k < len1; k++) {
                item = val[k];
                childNode = {};
                childNode[key] = item;
                lastChild = this.element(childNode);
              }
            } else if (isObject(val)) {
              if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertTextKey && key.indexOf((this || _global).stringify.convertTextKey) === 0) {
                lastChild = this.element(val);
              } else {
                lastChild = this.element(key);
                lastChild.element(val);
              }
            } else {
              lastChild = this.element(key, val);
            }
          }
        } else if (!(this || _global).options.keepNullNodes && text === null) {
          lastChild = this.dummy();
        } else {
          if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertTextKey && name.indexOf((this || _global).stringify.convertTextKey) === 0) {
            lastChild = this.text(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertCDataKey && name.indexOf((this || _global).stringify.convertCDataKey) === 0) {
            lastChild = this.cdata(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertCommentKey && name.indexOf((this || _global).stringify.convertCommentKey) === 0) {
            lastChild = this.comment(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertRawKey && name.indexOf((this || _global).stringify.convertRawKey) === 0) {
            lastChild = this.raw(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertPIKey && name.indexOf((this || _global).stringify.convertPIKey) === 0) {
            lastChild = this.instruction(name.substr((this || _global).stringify.convertPIKey.length), text);
          } else {
            lastChild = this.node(name, attributes, text);
          }
        }

        if (lastChild == null) {
          throw new Error("Could not create any elements with: " + name + ". " + this.debugInfo());
        }

        return lastChild;
      };

      XMLNode.prototype.insertBefore = function (name, attributes, text) {
        var child, i, newChild, refChild, removed;

        if (name != null ? name.type : void 0) {
          newChild = name;
          refChild = attributes;
          newChild.setParent(this || _global);

          if (refChild) {
            i = children.indexOf(refChild);
            removed = children.splice(i);
            children.push(newChild);
            Array.prototype.push.apply(children, removed);
          } else {
            children.push(newChild);
          }

          return newChild;
        } else {
          if ((this || _global).isRoot) {
            throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
          }

          i = (this || _global).parent.children.indexOf(this || _global);
          removed = (this || _global).parent.children.splice(i);
          child = (this || _global).parent.element(name, attributes, text);
          Array.prototype.push.apply((this || _global).parent.children, removed);
          return child;
        }
      };

      XMLNode.prototype.insertAfter = function (name, attributes, text) {
        var child, i, removed;

        if ((this || _global).isRoot) {
          throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
        }

        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.element(name, attributes, text);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return child;
      };

      XMLNode.prototype.remove = function () {
        var i, ref2;

        if ((this || _global).isRoot) {
          throw new Error("Cannot remove the root element. " + this.debugInfo());
        }

        i = (this || _global).parent.children.indexOf(this || _global);
        [].splice.apply((this || _global).parent.children, [i, i - i + 1].concat(ref2 = [])), ref2;
        return (this || _global).parent;
      };

      XMLNode.prototype.node = function (name, attributes, text) {
        var child, ref2;

        if (name != null) {
          name = getValue(name);
        }

        attributes || (attributes = {});
        attributes = getValue(attributes);

        if (!isObject(attributes)) {
          ref2 = [attributes, text], text = ref2[0], attributes = ref2[1];
        }

        child = new XMLElement(this || _global, name, attributes);

        if (text != null) {
          child.text(text);
        }

        (this || _global).children.push(child);

        return child;
      };

      XMLNode.prototype.text = function (value) {
        var child;

        if (isObject(value)) {
          this.element(value);
        }

        child = new XMLText(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.cdata = function (value) {
        var child;
        child = new XMLCData(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.comment = function (value) {
        var child;
        child = new XMLComment(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.commentBefore = function (value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i);
        child = (this || _global).parent.comment(value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.commentAfter = function (value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.comment(value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.raw = function (value) {
        var child;
        child = new XMLRaw(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.dummy = function () {
        var child;
        child = new XMLDummy(this || _global);
        return child;
      };

      XMLNode.prototype.instruction = function (target, value) {
        var insTarget, insValue, instruction, j, len;

        if (target != null) {
          target = getValue(target);
        }

        if (value != null) {
          value = getValue(value);
        }

        if (Array.isArray(target)) {
          for (j = 0, len = target.length; j < len; j++) {
            insTarget = target[j];
            this.instruction(insTarget);
          }
        } else if (isObject(target)) {
          for (insTarget in target) {
            if (!hasProp.call(target, insTarget)) continue;
            insValue = target[insTarget];
            this.instruction(insTarget, insValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          instruction = new XMLProcessingInstruction(this || _global, target, value);

          (this || _global).children.push(instruction);
        }

        return this || _global;
      };

      XMLNode.prototype.instructionBefore = function (target, value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i);
        child = (this || _global).parent.instruction(target, value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.instructionAfter = function (target, value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.instruction(target, value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.declaration = function (version, encoding, standalone) {
        var doc, xmldec;
        doc = this.document();
        xmldec = new XMLDeclaration(doc, version, encoding, standalone);

        if (doc.children.length === 0) {
          doc.children.unshift(xmldec);
        } else if (doc.children[0].type === NodeType.Declaration) {
          doc.children[0] = xmldec;
        } else {
          doc.children.unshift(xmldec);
        }

        return doc.root() || doc;
      };

      XMLNode.prototype.dtd = function (pubID, sysID) {
        var child, doc, doctype, i, j, k, len, len1, ref2, ref3;
        doc = this.document();
        doctype = new XMLDocType(doc, pubID, sysID);
        ref2 = doc.children;

        for (i = j = 0, len = ref2.length; j < len; i = ++j) {
          child = ref2[i];

          if (child.type === NodeType.DocType) {
            doc.children[i] = doctype;
            return doctype;
          }
        }

        ref3 = doc.children;

        for (i = k = 0, len1 = ref3.length; k < len1; i = ++k) {
          child = ref3[i];

          if (child.isRoot) {
            doc.children.splice(i, 0, doctype);
            return doctype;
          }
        }

        doc.children.push(doctype);
        return doctype;
      };

      XMLNode.prototype.up = function () {
        if ((this || _global).isRoot) {
          throw new Error("The root node has no parent. Use doc() if you need to get the document object.");
        }

        return (this || _global).parent;
      };

      XMLNode.prototype.root = function () {
        var node;
        node = this || _global;

        while (node) {
          if (node.type === NodeType.Document) {
            return node.rootObject;
          } else if (node.isRoot) {
            return node;
          } else {
            node = node.parent;
          }
        }
      };

      XMLNode.prototype.document = function () {
        var node;
        node = this || _global;

        while (node) {
          if (node.type === NodeType.Document) {
            return node;
          } else {
            node = node.parent;
          }
        }
      };

      XMLNode.prototype.end = function (options) {
        return this.document().end(options);
      };

      XMLNode.prototype.prev = function () {
        var i;
        i = (this || _global).parent.children.indexOf(this || _global);

        if (i < 1) {
          throw new Error("Already at the first node. " + this.debugInfo());
        }

        return (this || _global).parent.children[i - 1];
      };

      XMLNode.prototype.next = function () {
        var i;
        i = (this || _global).parent.children.indexOf(this || _global);

        if (i === -1 || i === (this || _global).parent.children.length - 1) {
          throw new Error("Already at the last node. " + this.debugInfo());
        }

        return (this || _global).parent.children[i + 1];
      };

      XMLNode.prototype.importDocument = function (doc) {
        var clonedRoot;
        clonedRoot = doc.root().clone();
        clonedRoot.parent = this || _global;
        clonedRoot.isRoot = false;

        (this || _global).children.push(clonedRoot);

        return this || _global;
      };

      XMLNode.prototype.debugInfo = function (name) {
        var ref2, ref3;
        name = name || (this || _global).name;

        if (name == null && !((ref2 = (this || _global).parent) != null ? ref2.name : void 0)) {
          return "";
        } else if (name == null) {
          return "parent: <" + (this || _global).parent.name + ">";
        } else if (!((ref3 = (this || _global).parent) != null ? ref3.name : void 0)) {
          return "node: <" + name + ">";
        } else {
          return "node: <" + name + ">, parent: <" + (this || _global).parent.name + ">";
        }
      };

      XMLNode.prototype.ele = function (name, attributes, text) {
        return this.element(name, attributes, text);
      };

      XMLNode.prototype.nod = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLNode.prototype.txt = function (value) {
        return this.text(value);
      };

      XMLNode.prototype.dat = function (value) {
        return this.cdata(value);
      };

      XMLNode.prototype.com = function (value) {
        return this.comment(value);
      };

      XMLNode.prototype.ins = function (target, value) {
        return this.instruction(target, value);
      };

      XMLNode.prototype.doc = function () {
        return this.document();
      };

      XMLNode.prototype.dec = function (version, encoding, standalone) {
        return this.declaration(version, encoding, standalone);
      };

      XMLNode.prototype.e = function (name, attributes, text) {
        return this.element(name, attributes, text);
      };

      XMLNode.prototype.n = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLNode.prototype.t = function (value) {
        return this.text(value);
      };

      XMLNode.prototype.d = function (value) {
        return this.cdata(value);
      };

      XMLNode.prototype.c = function (value) {
        return this.comment(value);
      };

      XMLNode.prototype.r = function (value) {
        return this.raw(value);
      };

      XMLNode.prototype.i = function (target, value) {
        return this.instruction(target, value);
      };

      XMLNode.prototype.u = function () {
        return this.up();
      };

      XMLNode.prototype.importXMLBuilder = function (doc) {
        return this.importDocument(doc);
      };

      XMLNode.prototype.replaceChild = function (newChild, oldChild) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.removeChild = function (oldChild) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.appendChild = function (newChild) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.hasChildNodes = function () {
        return (this || _global).children.length !== 0;
      };

      XMLNode.prototype.cloneNode = function (deep) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.normalize = function () {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.isSupported = function (feature, version) {
        return true;
      };

      XMLNode.prototype.hasAttributes = function () {
        return (this || _global).attribs.length !== 0;
      };

      XMLNode.prototype.compareDocumentPosition = function (other) {
        var ref, res;
        ref = this || _global;

        if (ref === other) {
          return 0;
        } else if (this.document() !== other.document()) {
          res = DocumentPosition.Disconnected | DocumentPosition.ImplementationSpecific;

          if (Math.random() < 0.5) {
            res |= DocumentPosition.Preceding;
          } else {
            res |= DocumentPosition.Following;
          }

          return res;
        } else if (ref.isAncestor(other)) {
          return DocumentPosition.Contains | DocumentPosition.Preceding;
        } else if (ref.isDescendant(other)) {
          return DocumentPosition.Contains | DocumentPosition.Following;
        } else if (ref.isPreceding(other)) {
          return DocumentPosition.Preceding;
        } else {
          return DocumentPosition.Following;
        }
      };

      XMLNode.prototype.isSameNode = function (other) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.lookupPrefix = function (namespaceURI) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.isDefaultNamespace = function (namespaceURI) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.lookupNamespaceURI = function (prefix) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.isEqualNode = function (node) {
        var i, j, ref2;

        if (node.nodeType !== (this || _global).nodeType) {
          return false;
        }

        if (node.children.length !== (this || _global).children.length) {
          return false;
        }

        for (i = j = 0, ref2 = (this || _global).children.length - 1; 0 <= ref2 ? j <= ref2 : j >= ref2; i = 0 <= ref2 ? ++j : --j) {
          if (!(this || _global).children[i].isEqualNode(node.children[i])) {
            return false;
          }
        }

        return true;
      };

      XMLNode.prototype.getFeature = function (feature, version) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.setUserData = function (key, data, handler) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.getUserData = function (key) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLNode.prototype.contains = function (other) {
        if (!other) {
          return false;
        }

        return other === (this || _global) || this.isDescendant(other);
      };

      XMLNode.prototype.isDescendant = function (node) {
        var child, isDescendantChild, j, len, ref2;
        ref2 = (this || _global).children;

        for (j = 0, len = ref2.length; j < len; j++) {
          child = ref2[j];

          if (node === child) {
            return true;
          }

          isDescendantChild = child.isDescendant(node);

          if (isDescendantChild) {
            return true;
          }
        }

        return false;
      };

      XMLNode.prototype.isAncestor = function (node) {
        return node.isDescendant(this || _global);
      };

      XMLNode.prototype.isPreceding = function (node) {
        var nodePos, thisPos;
        nodePos = this.treePosition(node);
        thisPos = this.treePosition(this || _global);

        if (nodePos === -1 || thisPos === -1) {
          return false;
        } else {
          return nodePos < thisPos;
        }
      };

      XMLNode.prototype.isFollowing = function (node) {
        var nodePos, thisPos;
        nodePos = this.treePosition(node);
        thisPos = this.treePosition(this || _global);

        if (nodePos === -1 || thisPos === -1) {
          return false;
        } else {
          return nodePos > thisPos;
        }
      };

      XMLNode.prototype.treePosition = function (node) {
        var found, pos;
        pos = 0;
        found = false;
        this.foreachTreeNode(this.document(), function (childNode) {
          pos++;

          if (!found && childNode === node) {
            return found = true;
          }
        });

        if (found) {
          return pos;
        } else {
          return -1;
        }
      };

      XMLNode.prototype.foreachTreeNode = function (node, func) {
        var child, j, len, ref2, res;
        node || (node = this.document());
        ref2 = node.children;

        for (j = 0, len = ref2.length; j < len; j++) {
          child = ref2[j];

          if (res = func(child)) {
            return res;
          } else {
            res = this.foreachTreeNode(child, func);

            if (res) {
              return res;
            }
          }
        }
      };

      return XMLNode;
    }();
  }).call(exports);
  return exports;
}