import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _NodeTypeDew } from "./NodeType";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLDummy,
        XMLNode,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLNode = _XMLNodeDew();
    NodeType = _NodeTypeDew();

    exports = XMLDummy = function (superClass) {
      extend(XMLDummy, superClass);

      function XMLDummy(parent) {
        XMLDummy.__super__.constructor.call(this || _global, parent);

        (this || _global).type = NodeType.Dummy;
      }

      XMLDummy.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLDummy.prototype.toString = function (options) {
        return "";
      };

      return XMLDummy;
    }(XMLNode);
  }).call(exports);
  return exports;
}