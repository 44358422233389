import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLCharacterDataDew } from "./XMLCharacterData";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLCharacterData,
        XMLText,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    NodeType = _NodeTypeDew();
    XMLCharacterData = _XMLCharacterDataDew();

    exports = XMLText = function (superClass) {
      extend(XMLText, superClass);

      function XMLText(parent, text) {
        XMLText.__super__.constructor.call(this || _global, parent);

        if (text == null) {
          throw new Error("Missing element text. " + this.debugInfo());
        }

        (this || _global).name = "#text";
        (this || _global).type = NodeType.Text;
        (this || _global).value = (this || _global).stringify.text(text);
      }

      Object.defineProperty(XMLText.prototype, "isElementContentWhitespace", {
        get: function () {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });
      Object.defineProperty(XMLText.prototype, "wholeText", {
        get: function () {
          var next, prev, str;
          str = "";
          prev = (this || _global).previousSibling;

          while (prev) {
            str = prev.data + str;
            prev = prev.previousSibling;
          }

          str += (this || _global).data;
          next = (this || _global).nextSibling;

          while (next) {
            str = str + next.data;
            next = next.nextSibling;
          }

          return str;
        }
      });

      XMLText.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLText.prototype.toString = function (options) {
        return (this || _global).options.writer.text(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLText.prototype.splitText = function (offset) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      XMLText.prototype.replaceWholeText = function (content) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      };

      return XMLText;
    }(XMLCharacterData);
  }).call(exports);
  return exports;
}