var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDOMErrorHandler;

    exports = XMLDOMErrorHandler = function () {
      function XMLDOMErrorHandler() {}

      XMLDOMErrorHandler.prototype.handleError = function (error) {
        throw new Error(error);
      };

      return XMLDOMErrorHandler;
    }();
  }).call(exports);
  return exports;
}