import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLWriterBaseDew } from "./XMLWriterBase";
import { dew as _WriterStateDew } from "./WriterState";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        WriterState,
        XMLStreamWriter,
        XMLWriterBase,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    NodeType = _NodeTypeDew();
    XMLWriterBase = _XMLWriterBaseDew();
    WriterState = _WriterStateDew();

    exports = XMLStreamWriter = function (superClass) {
      extend(XMLStreamWriter, superClass);

      function XMLStreamWriter(stream, options) {
        (this || _global).stream = stream;

        XMLStreamWriter.__super__.constructor.call(this || _global, options);
      }

      XMLStreamWriter.prototype.endline = function (node, options, level) {
        if (node.isLastRootNode && options.state === WriterState.CloseTag) {
          return "";
        } else {
          return XMLStreamWriter.__super__.endline.call(this || _global, node, options, level);
        }
      };

      XMLStreamWriter.prototype.document = function (doc, options) {
        var child, i, j, k, len, len1, ref, ref1, results;
        ref = doc.children;

        for (i = j = 0, len = ref.length; j < len; i = ++j) {
          child = ref[i];
          child.isLastRootNode = i === doc.children.length - 1;
        }

        options = this.filterOptions(options);
        ref1 = doc.children;
        results = [];

        for (k = 0, len1 = ref1.length; k < len1; k++) {
          child = ref1[k];
          results.push(this.writeChildNode(child, options, 0));
        }

        return results;
      };

      XMLStreamWriter.prototype.attribute = function (att, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.attribute.call(this || _global, att, options, level));
      };

      XMLStreamWriter.prototype.cdata = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.cdata.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.comment = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.comment.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.declaration = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.declaration.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.docType = function (node, options, level) {
        var child, j, len, ref;
        level || (level = 0);
        this.openNode(node, options, level);
        options.state = WriterState.OpenTag;

        (this || _global).stream.write(this.indent(node, options, level));

        (this || _global).stream.write("<!DOCTYPE " + node.root().name);

        if (node.pubID && node.sysID) {
          (this || _global).stream.write(" PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"");
        } else if (node.sysID) {
          (this || _global).stream.write(" SYSTEM \"" + node.sysID + "\"");
        }

        if (node.children.length > 0) {
          (this || _global).stream.write(" [");

          (this || _global).stream.write(this.endline(node, options, level));

          options.state = WriterState.InsideTag;
          ref = node.children;

          for (j = 0, len = ref.length; j < len; j++) {
            child = ref[j];
            this.writeChildNode(child, options, level + 1);
          }

          options.state = WriterState.CloseTag;

          (this || _global).stream.write("]");
        }

        options.state = WriterState.CloseTag;

        (this || _global).stream.write(options.spaceBeforeSlash + ">");

        (this || _global).stream.write(this.endline(node, options, level));

        options.state = WriterState.None;
        return this.closeNode(node, options, level);
      };

      XMLStreamWriter.prototype.element = function (node, options, level) {
        var att, child, childNodeCount, firstChildNode, j, len, name, prettySuppressed, ref, ref1;
        level || (level = 0);
        this.openNode(node, options, level);
        options.state = WriterState.OpenTag;

        (this || _global).stream.write(this.indent(node, options, level) + "<" + node.name);

        ref = node.attribs;

        for (name in ref) {
          if (!hasProp.call(ref, name)) continue;
          att = ref[name];
          this.attribute(att, options, level);
        }

        childNodeCount = node.children.length;
        firstChildNode = childNodeCount === 0 ? null : node.children[0];

        if (childNodeCount === 0 || node.children.every(function (e) {
          return (e.type === NodeType.Text || e.type === NodeType.Raw) && e.value === "";
        })) {
          if (options.allowEmpty) {
            (this || _global).stream.write(">");

            options.state = WriterState.CloseTag;

            (this || _global).stream.write("</" + node.name + ">");
          } else {
            options.state = WriterState.CloseTag;

            (this || _global).stream.write(options.spaceBeforeSlash + "/>");
          }
        } else if (options.pretty && childNodeCount === 1 && (firstChildNode.type === NodeType.Text || firstChildNode.type === NodeType.Raw) && firstChildNode.value != null) {
          (this || _global).stream.write(">");

          options.state = WriterState.InsideTag;
          options.suppressPrettyCount++;
          prettySuppressed = true;
          this.writeChildNode(firstChildNode, options, level + 1);
          options.suppressPrettyCount--;
          prettySuppressed = false;
          options.state = WriterState.CloseTag;

          (this || _global).stream.write("</" + node.name + ">");
        } else {
          (this || _global).stream.write(">" + this.endline(node, options, level));

          options.state = WriterState.InsideTag;
          ref1 = node.children;

          for (j = 0, len = ref1.length; j < len; j++) {
            child = ref1[j];
            this.writeChildNode(child, options, level + 1);
          }

          options.state = WriterState.CloseTag;

          (this || _global).stream.write(this.indent(node, options, level) + "</" + node.name + ">");
        }

        (this || _global).stream.write(this.endline(node, options, level));

        options.state = WriterState.None;
        return this.closeNode(node, options, level);
      };

      XMLStreamWriter.prototype.processingInstruction = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.processingInstruction.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.raw = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.raw.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.text = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.text.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.dtdAttList = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.dtdAttList.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.dtdElement = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.dtdElement.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.dtdEntity = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.dtdEntity.call(this || _global, node, options, level));
      };

      XMLStreamWriter.prototype.dtdNotation = function (node, options, level) {
        return (this || _global).stream.write(XMLStreamWriter.__super__.dtdNotation.call(this || _global, node, options, level));
      };

      return XMLStreamWriter;
    }(XMLWriterBase);
  }).call(exports);
  return exports;
}