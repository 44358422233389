import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLDOMImplementationDew } from "./XMLDOMImplementation";
import { dew as _XMLDocumentDew } from "./XMLDocument";
import { dew as _XMLDocumentCBDew } from "./XMLDocumentCB";
import { dew as _XMLStringWriterDew } from "./XMLStringWriter";
import { dew as _XMLStreamWriterDew } from "./XMLStreamWriter";
import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _WriterStateDew } from "./WriterState";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType, WriterState, XMLDOMImplementation, XMLDocument, XMLDocumentCB, XMLStreamWriter, XMLStringWriter, assign, isFunction, ref;
    ref = _UtilityDew(), assign = ref.assign, isFunction = ref.isFunction;
    XMLDOMImplementation = _XMLDOMImplementationDew();
    XMLDocument = _XMLDocumentDew();
    XMLDocumentCB = _XMLDocumentCBDew();
    XMLStringWriter = _XMLStringWriterDew();
    XMLStreamWriter = _XMLStreamWriterDew();
    NodeType = _NodeTypeDew();
    WriterState = _WriterStateDew();

    exports.create = function (name, xmldec, doctype, options) {
      var doc, root;

      if (name == null) {
        throw new Error("Root element needs a name.");
      }

      options = assign({}, xmldec, doctype, options);
      doc = new XMLDocument(options);
      root = doc.element(name);

      if (!options.headless) {
        doc.declaration(options);

        if (options.pubID != null || options.sysID != null) {
          doc.dtd(options);
        }
      }

      return root;
    };

    exports.begin = function (options, onData, onEnd) {
      var ref1;

      if (isFunction(options)) {
        ref1 = [options, onData], onData = ref1[0], onEnd = ref1[1];
        options = {};
      }

      if (onData) {
        return new XMLDocumentCB(options, onData, onEnd);
      } else {
        return new XMLDocument(options);
      }
    };

    exports.stringWriter = function (options) {
      return new XMLStringWriter(options);
    };

    exports.streamWriter = function (stream, options) {
      return new XMLStreamWriter(stream, options);
    };

    exports.implementation = new XMLDOMImplementation();
    exports.nodeType = NodeType;
    exports.writerState = WriterState;
  }).call(exports);
  return exports;
}