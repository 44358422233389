import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLDTDAttListDew } from "./XMLDTDAttList";
import { dew as _XMLDTDEntityDew } from "./XMLDTDEntity";
import { dew as _XMLDTDElementDew } from "./XMLDTDElement";
import { dew as _XMLDTDNotationDew } from "./XMLDTDNotation";
import { dew as _XMLNamedNodeMapDew } from "./XMLNamedNodeMap";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLDTDAttList,
        XMLDTDElement,
        XMLDTDEntity,
        XMLDTDNotation,
        XMLDocType,
        XMLNamedNodeMap,
        XMLNode,
        isObject,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    isObject = _UtilityDew().isObject;
    XMLNode = _XMLNodeDew();
    NodeType = _NodeTypeDew();
    XMLDTDAttList = _XMLDTDAttListDew();
    XMLDTDEntity = _XMLDTDEntityDew();
    XMLDTDElement = _XMLDTDElementDew();
    XMLDTDNotation = _XMLDTDNotationDew();
    XMLNamedNodeMap = _XMLNamedNodeMapDew();

    exports = XMLDocType = function (superClass) {
      extend(XMLDocType, superClass);

      function XMLDocType(parent, pubID, sysID) {
        var child, i, len, ref, ref1, ref2;

        XMLDocType.__super__.constructor.call(this || _global, parent);

        (this || _global).type = NodeType.DocType;

        if (parent.children) {
          ref = parent.children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            if (child.type === NodeType.Element) {
              (this || _global).name = child.name;
              break;
            }
          }
        }

        (this || _global).documentObject = parent;

        if (isObject(pubID)) {
          ref1 = pubID, pubID = ref1.pubID, sysID = ref1.sysID;
        }

        if (sysID == null) {
          ref2 = [pubID, sysID], sysID = ref2[0], pubID = ref2[1];
        }

        if (pubID != null) {
          (this || _global).pubID = (this || _global).stringify.dtdPubID(pubID);
        }

        if (sysID != null) {
          (this || _global).sysID = (this || _global).stringify.dtdSysID(sysID);
        }
      }

      Object.defineProperty(XMLDocType.prototype, "entities", {
        get: function () {
          var child, i, len, nodes, ref;
          nodes = {};
          ref = (this || _global).children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            if (child.type === NodeType.EntityDeclaration && !child.pe) {
              nodes[child.name] = child;
            }
          }

          return new XMLNamedNodeMap(nodes);
        }
      });
      Object.defineProperty(XMLDocType.prototype, "notations", {
        get: function () {
          var child, i, len, nodes, ref;
          nodes = {};
          ref = (this || _global).children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            if (child.type === NodeType.NotationDeclaration) {
              nodes[child.name] = child;
            }
          }

          return new XMLNamedNodeMap(nodes);
        }
      });
      Object.defineProperty(XMLDocType.prototype, "publicId", {
        get: function () {
          return (this || _global).pubID;
        }
      });
      Object.defineProperty(XMLDocType.prototype, "systemId", {
        get: function () {
          return (this || _global).sysID;
        }
      });
      Object.defineProperty(XMLDocType.prototype, "internalSubset", {
        get: function () {
          throw new Error("This DOM method is not implemented." + this.debugInfo());
        }
      });

      XMLDocType.prototype.element = function (name, value) {
        var child;
        child = new XMLDTDElement(this || _global, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.attList = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        var child;
        child = new XMLDTDAttList(this || _global, elementName, attributeName, attributeType, defaultValueType, defaultValue);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.entity = function (name, value) {
        var child;
        child = new XMLDTDEntity(this || _global, false, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.pEntity = function (name, value) {
        var child;
        child = new XMLDTDEntity(this || _global, true, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.notation = function (name, value) {
        var child;
        child = new XMLDTDNotation(this || _global, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.toString = function (options) {
        return (this || _global).options.writer.docType(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLDocType.prototype.ele = function (name, value) {
        return this.element(name, value);
      };

      XMLDocType.prototype.att = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        return this.attList(elementName, attributeName, attributeType, defaultValueType, defaultValue);
      };

      XMLDocType.prototype.ent = function (name, value) {
        return this.entity(name, value);
      };

      XMLDocType.prototype.pent = function (name, value) {
        return this.pEntity(name, value);
      };

      XMLDocType.prototype.not = function (name, value) {
        return this.notation(name, value);
      };

      XMLDocType.prototype.up = function () {
        return this.root() || (this || _global).documentObject;
      };

      XMLDocType.prototype.isEqualNode = function (node) {
        if (!XMLDocType.__super__.isEqualNode.apply(this || _global, arguments).isEqualNode(node)) {
          return false;
        }

        if (node.name !== (this || _global).name) {
          return false;
        }

        if (node.publicId !== (this || _global).publicId) {
          return false;
        }

        if (node.systemId !== (this || _global).systemId) {
          return false;
        }

        return true;
      };

      return XMLDocType;
    }(XMLNode);
  }).call(exports);
  return exports;
}