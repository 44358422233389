import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType, XMLAttribute, XMLNode;
    NodeType = _NodeTypeDew();
    XMLNode = _XMLNodeDew();

    exports = XMLAttribute = function () {
      function XMLAttribute(parent, name, value) {
        (this || _global).parent = parent;

        if ((this || _global).parent) {
          (this || _global).options = (this || _global).parent.options;
          (this || _global).stringify = (this || _global).parent.stringify;
        }

        if (name == null) {
          throw new Error("Missing attribute name. " + this.debugInfo(name));
        }

        (this || _global).name = (this || _global).stringify.name(name);
        (this || _global).value = (this || _global).stringify.attValue(value);
        (this || _global).type = NodeType.Attribute;
        (this || _global).isId = false;
        (this || _global).schemaTypeInfo = null;
      }

      Object.defineProperty(XMLAttribute.prototype, "nodeType", {
        get: function () {
          return (this || _global).type;
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "ownerElement", {
        get: function () {
          return (this || _global).parent;
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "textContent", {
        get: function () {
          return (this || _global).value;
        },
        set: function (value) {
          return (this || _global).value = value || "";
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "namespaceURI", {
        get: function () {
          return "";
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "prefix", {
        get: function () {
          return "";
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "localName", {
        get: function () {
          return (this || _global).name;
        }
      });
      Object.defineProperty(XMLAttribute.prototype, "specified", {
        get: function () {
          return true;
        }
      });

      XMLAttribute.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLAttribute.prototype.toString = function (options) {
        return (this || _global).options.writer.attribute(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLAttribute.prototype.debugInfo = function (name) {
        name = name || (this || _global).name;

        if (name == null) {
          return "parent: <" + (this || _global).parent.name + ">";
        } else {
          return "attribute: {" + name + "}, parent: <" + (this || _global).parent.name + ">";
        }
      };

      XMLAttribute.prototype.isEqualNode = function (node) {
        if (node.namespaceURI !== (this || _global).namespaceURI) {
          return false;
        }

        if (node.prefix !== (this || _global).prefix) {
          return false;
        }

        if (node.localName !== (this || _global).localName) {
          return false;
        }

        if (node.value !== (this || _global).value) {
          return false;
        }

        return true;
      };

      return XMLAttribute;
    }();
  }).call(exports);
  return exports;
}