import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLCharacterDataDew } from "./XMLCharacterData";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLCharacterData,
        XMLProcessingInstruction,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    NodeType = _NodeTypeDew();
    XMLCharacterData = _XMLCharacterDataDew();

    exports = XMLProcessingInstruction = function (superClass) {
      extend(XMLProcessingInstruction, superClass);

      function XMLProcessingInstruction(parent, target, value) {
        XMLProcessingInstruction.__super__.constructor.call(this || _global, parent);

        if (target == null) {
          throw new Error("Missing instruction target. " + this.debugInfo());
        }

        (this || _global).type = NodeType.ProcessingInstruction;
        (this || _global).target = (this || _global).stringify.insTarget(target);
        (this || _global).name = (this || _global).target;

        if (value) {
          (this || _global).value = (this || _global).stringify.insValue(value);
        }
      }

      XMLProcessingInstruction.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLProcessingInstruction.prototype.toString = function (options) {
        return (this || _global).options.writer.processingInstruction(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      XMLProcessingInstruction.prototype.isEqualNode = function (node) {
        if (!XMLProcessingInstruction.__super__.isEqualNode.apply(this || _global, arguments).isEqualNode(node)) {
          return false;
        }

        if (node.target !== (this || _global).target) {
          return false;
        }

        return true;
      };

      return XMLProcessingInstruction;
    }(XMLCharacterData);
  }).call(exports);
  return exports;
}