import { dew as _NodeTypeDew } from "./NodeType";
import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var NodeType,
        XMLNode,
        XMLRaw,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    NodeType = _NodeTypeDew();
    XMLNode = _XMLNodeDew();

    exports = XMLRaw = function (superClass) {
      extend(XMLRaw, superClass);

      function XMLRaw(parent, text) {
        XMLRaw.__super__.constructor.call(this || _global, parent);

        if (text == null) {
          throw new Error("Missing raw text. " + this.debugInfo());
        }

        (this || _global).type = NodeType.Raw;
        (this || _global).value = (this || _global).stringify.raw(text);
      }

      XMLRaw.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLRaw.prototype.toString = function (options) {
        return (this || _global).options.writer.raw(this || _global, (this || _global).options.writer.filterOptions(options));
      };

      return XMLRaw;
    }(XMLNode);
  }).call(exports);
  return exports;
}