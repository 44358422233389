var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLNamedNodeMap;

    exports = XMLNamedNodeMap = function () {
      function XMLNamedNodeMap(nodes) {
        (this || _global).nodes = nodes;
      }

      Object.defineProperty(XMLNamedNodeMap.prototype, "length", {
        get: function () {
          return Object.keys((this || _global).nodes).length || 0;
        }
      });

      XMLNamedNodeMap.prototype.clone = function () {
        return (this || _global).nodes = null;
      };

      XMLNamedNodeMap.prototype.getNamedItem = function (name) {
        return (this || _global).nodes[name];
      };

      XMLNamedNodeMap.prototype.setNamedItem = function (node) {
        var oldNode;
        oldNode = (this || _global).nodes[node.nodeName];
        (this || _global).nodes[node.nodeName] = node;
        return oldNode || null;
      };

      XMLNamedNodeMap.prototype.removeNamedItem = function (name) {
        var oldNode;
        oldNode = (this || _global).nodes[name];
        delete (this || _global).nodes[name];
        return oldNode || null;
      };

      XMLNamedNodeMap.prototype.item = function (index) {
        return (this || _global).nodes[Object.keys((this || _global).nodes)[index]] || null;
      };

      XMLNamedNodeMap.prototype.getNamedItemNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented.");
      };

      XMLNamedNodeMap.prototype.setNamedItemNS = function (node) {
        throw new Error("This DOM method is not implemented.");
      };

      XMLNamedNodeMap.prototype.removeNamedItemNS = function (namespaceURI, localName) {
        throw new Error("This DOM method is not implemented.");
      };

      return XMLNamedNodeMap;
    }();
  }).call(exports);
  return exports;
}